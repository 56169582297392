.forgotPasswordPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 650px !important;
    width: 650px !important;
    margin: auto;

    & .userExistingForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    & .resetPasswordForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        & .resendCodeRow {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            & .questionIcon {
                margin-right: 5px;
            }

            & .buttonSide {
                margin-left: auto;
                margin-right: 0;

                & .resendLink {
                    padding: 0px;
                }
            }

            & :global(.ant-statistic-content) {
                font-size: inherit;
                & :global(.ant-statistic-content-prefix) {
                    color: #777a7e;
                }
                & :global(.ant-statistic-content-value) {
                    font-weight: bold;
                }
                & :global(.ant-statistic-content-suffix) {
                    color: #777a7e;
                }
            }
        }

        & .button {
            margin-left: auto;
            margin-right: 0;
            width: 25%;
        }
    }
}
