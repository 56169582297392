.alert {
    margin-bottom: 10px;
}

.description {
    padding-bottom: 20px;
    color: #777A7E;
}

.noData {
    margin-top: 10px;
}

.notificationCard {
    width: 500px;
    padding-bottom: 5px;

    & :global(.ant-card) {
        background-color: #E8EEF2;

        & :global(.ant-card-body) {
            padding: 10px;
        }
    }
    
    & .title {
        padding: 0px;
        margin-left: 0px;
        margin-bottom: 5px;
        font-weight: bold;
    }
    & .footer {
        display: flex;
        margin-top: 20px;

        & .messageType {
            display: flex;
            margin-left: auto;
            margin-right: 5px;
        }
        & .bellIcon {
            margin-left: auto;
            margin-right: 10px;
        }
    }
}

.modal {
    & .title {
        display: flex;
    }

    & .rightItem {
        margin-left: auto;
        margin-right: 5px;
    }

    & .dividerTop {
        margin-top: 10px;
    }

    & .dividerBottom {
        margin-bottom: 10px;
    }
}
