.spinnerWrapper {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & :global(.ant-typography) {
        margin-bottom: 0 !important;
    }

    & .spinnerBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    & > :global(.ant-spin-nested-loading) {
        margin-right: 25px;
    }

    & > :global(.ant-typography) {
        margin-bottom: 0px;
    }

    & > .spinner {

        &.medium {

        }
        &.large {

        }
        &.xLarge {

        }
    }
}

