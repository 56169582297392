.wrapper {

    & .title {
        margin-bottom: 0 !important;
    }

    & .divider {
        margin: 15px 0 !important
    }

    & .verticalList {

        width: 100%;

        & .noDataWrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        & .verticalListItem {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & > .verticalListItemTitle {
                & * {
                    margin-bottom: 0 !important;
                    overflow-wrap: break-word !important;
                }
            }

            & > .verticalListItemBody {

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                & > .verticalListItemAvatar {}

                & > .verticalListItemContent {
                    flex-grow: 2;
                    overflow-wrap: break-word;
                    margin: 10px 20px;
                }

                & > .secondaryContent {
                    margin-left: auto;
                }

            }

        }
    }
}

