:root {
    --max-width: 1480px;
    --test: initial;
    --formButtonBackground: #e5f0f5;
    --formButtonRadius: 8px;
    --formButtonFrontColor: #777a7e;
    --fa-primary-color: #7cb305;
    --fa-secondary-color: #ffc53d;
}

:global(.ant-layout-content) {
    & > div {
        height: 100% !important;
    }
}

/* header and top menu */
:global(.header__header) {
    & :global(.header__brand) {
        color: #2B6777;

        & > * span {
            color: #c7c7c7;
            font-weight: bold;
            font-size: 18px;
        }
    }

    & :global(.header__mainMenu) {
        font-weight: bold;
        font-size: 30px;
        width: 100% !important;
    }
    & :global(.horizontal-menu__horizontalMenu) {
        justify-content: flex-end;
    }

    & :global(.header__profile) {
        & > * div {
            color: #aabac1;
        }
    }
    & :global(.ant-space) {
        width: 100%;
        justify-content: flex-end;
        & > div:last-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

/* Drop down Menu items - whole menu box component */ 
:global(.ant-menu.ant-menu-sub.ant-menu-vertical) {
    background-color: #E8EEF2;
    width: 110%;
    & > div {
        width: 50%;
    }
}

/* Drop down Menu items - individual menu items with hover */
:global(.horizontal-menu__subMenuItem:hover),
:global(.ant-menu-item.ant-menu-item-selected.horizontal-menu__subMenuItem) {
    background-color: #F5FAFA !important;
    color:#52AB98;
}

/* Drop down Menu items - individual menu items */ 
:global(.horizontal-menu__subMenuItem),
:global(.ant-dropdown-menu-title-content) {
    display: flex !important;
    
    & > div {
        width: 20%;
    }
}


/* drop down menu for user profile */
:global(div.ant-dropdown.header__profileDropdown) {
    color: #627287;
    padding-top: 20px !important;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

/* login page */
:global(.login__loginPage) {
    & > :global(.login__header) {
        color: #345f81;

        & > * h2 {
            font-weight: bold;
            color: #345f81;
        }
    }
}

:global(.login-form__loginForm) {
    & > * input {
        text-align: left !important;
        margin-left: 10px;
    }
}

:global(.login__description) {
    & > div {
        padding-top: 10px;
        color: #777a7e;
    }
}

:global(.devices__title) {
    & > :global(.icon__iconWrapper) {
        padding-left: 20px;
        color: #2B6777;
    }
}

/* Form item */
:global(.ant-form-item-no-colon),
:global(.ant-form-item-required.ant-form-item-no-colon) {
    color: #777a7e !important;
}

/* Card */ 
:global(.ant-card-head-title) {
    color: #2B6777;
}

/* Table */ 
:global(.ant-table-thead),
:global(.ant-table-column-title),
:global(.ant-typography.repository__requiredField),
:global(.ant-typography.list__requiredField) {
    color: #2B6777 !important;
}

:global(.ant-table-thead:hover) {
    background-color:#e2eaf0;
}

:global(td.ant-table-cell),
:global(.ant-table-footer) {
    background-color: #F5F5F5 !important;
}

/* Tab */
:global(.basic-tab__tabWrapper) {
    & :global(.ant-tabs-tab-active > .ant-tabs-tab-btn) {
        color: #52AB98 !important;
    }
    & :global(.ant-tabs-ink-bar) {
        background: #52AB98;
    }
}

/* Horizontal stepper */
:global(.horizontal-stepper__horizontalStepper) {
    & :global(.ant-steps-item::before) {
        background-color: #52AB98 !important;
    }
    & :global(.ant-steps-item-active .ant-steps-item-title) {
        font-weight: 500;
    }
    & :global(.ant-steps-item-active .ant-steps-item-icon) {
        border-color: #52AB98;
        background: #52AB98;
        & > span {
            color: #F5F5F5;
        }
    }
    & :global(.ant-steps-item-active .ant-steps-item-content) {
        & > div {
            color: black !important;
        }
    }
}

/* Hyperlink */
:global(.ant-btn.ant-btn-link:hover),
:global(.ant-btn.ant-btn-link:focus),
:global(.ant-btn.ant-btn-link:active) {
    color: #2e695c;
}

/* Button - primary */
:global(.ant-btn.ant-btn-primary),
:global(.ant-btn.ant-btn-primary:hover),
:global(.ant-btn.ant-btn-primary:focus),
:global(.ant-btn.ant-btn-primary:active) {
    border-color: #52AB98;
    background: #52AB98;
    color: #F4F7F9;
}

/* Button - default */
:global(.ant-btn.ant-btn-default),
:global(.ant-btn.ant-btn-default:hover),
:global(.ant-btn.ant-btn-default:focus),
:global(.ant-btn.ant-btn-default:active) {
    background: #F4F7F9;
    color: #52AB98;
}

/* Empty component image icon */
:global(.ant-empty-img-simple-path) {
    fill: #F5F5F5 !important;
}

/* Notification images */ 
:global(.anticon.anticon-exclamation-circle)
{
    color: #52AB98 !important;
}

:global(anticon.anticon-info-circle.ant-notification-notice-icon.ant-notification-notice-icon-info) {
    color: #52AB98 !important;
}

/* Upload component background colour */
:global(.ant-upload) {
    background-color: #F5F5F5;
}

