.nextVersion {
    flex: 0 0 400px;

    & .itemContainer {
        display: flex;
        align-items: center;
        height: 28px;
        margin-bottom: 1rem;

        & > label {
            flex: 0 0 100px;
            margin-right: 1.5rem;
        }

        & > p {
            flex-grow: 1;
            margin: 0;
            padding: 2px 12px;
            border: 1px solid #d9d9d9;
        }
    }

    & :last-child {
        margin-bottom: 0;
    }
}
