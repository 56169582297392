.pane {
    width: 100%;
}
.uploadAlert {
    margin-bottom: 5px;
}

.table {
    & :global(.ant-form-item-label) {
        display: none;
    }
    & :global(.ant-form-item) {
        margin-bottom: auto !important;
    }
    & .addNewBtn {
        width: 100%;
    }
    & .linkButton {
        padding-left: 0px;
    }
    & .requiredField::before {
        content: '*';
        color: red;
    }
}
.control {
    display: flex;
}

.spinning {
    display: block !important;
}
.row {
    display: flex;
    padding: 24px;
    justify-content: flex-end;
}

.modal {
    width: 100%;

    & :global(.ant-modal-content) {
        width: 1000px;
    }

    & .title {
        display: flex;
    }

    & .rightItem {
        margin-left: auto;
        margin-right: 5px;
    }

    & .dividerTop {
        margin-top: 10px;
    }

    & .dividerBottom {
        margin-bottom: 10px;
    }
}

