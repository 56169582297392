.drawer {
    & :global(.ant-drawer-content-wrapper) {
        width: 550px !important;
    }
}

.basicForm {
    display: block;
    width: 100%;
}

.actions {
    display: inline-flex !important;
}
