.table {
    & button {
        width: 100%;
    }

    & :global(.ant-form-item-label) {
        display: none;
    }

    & :global(.ant-form-item) {
        margin-bottom: auto !important;
    }
}

.wrapper {
    height: auto;

    & .progress {
        margin: 14px 0 0 5px;
    }
}

.spinning {
    display: block !important;
}

.headerSelectItem {
    width: 100%;
}

.selectPartner {
    width: 100%;
}
