.iconWrapper {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.asPrefix {
        & > svg {
            margin-right: 20px !important;;
        }
    }

    &.danger {
        color: var(--ant-error-color);
    }

    & > .icon {

    }

    & .label {
        margin-left: 10px;
    }
	
	& .hover {
        cursor: pointer;
    }
}