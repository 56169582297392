.device-details {
    width: 99vw;
    transition: margin 0.3s;
    margin-left: calc(calc(-99vw + 1280px) / 2);

    & :global(.ant-card-extra) {
        padding: 0px;
    }

    & .status-spinner {
        display: inline-flex;
        margin-left: 10px;
    }

    & :global(.ant-statistic) {
        padding: 10px;
    }

    & :global(.ant-card-body) {
        padding: 0px !important;
    }

    & :global(.ant-card-small > .ant-card-body) {
        background-color: white;
        padding: none !important;

        & > :global(.ant-collapse-header),
        :global(.ant-collapse-content-box) {
            background-color: white;
        }
    }

    & :global(.horizontal-collapse-layout__collapse) {
        background: white;
    }

    & .left-panel-details {
        & :global(.basic-form__wrapper) {
            border: none;
        }
    }

    & .horizontal-collapse-panel {
        min-height: 900px;

        & .tabPane {
            width: 100%;

            & :global(.ant-btn-link) {
                text-wrap: balance;
                overflow-wrap: anywhere;
                height: auto;
                text-align: left;
                padding: 0;
            }

            & .jobId {
                overflow-wrap: anywhere;
                min-width: 150px;
                width: 100%;
                display: block;
            }
        }

        & .reload {
            float: right;
        }

        & .row {
            display: flex;
        }

        & .layout {
            display: flex;

            & div {
                width: 50%;
            }
        }

        & .label {
            padding: 10px 5px 10px 0px;
            background-color: #f4f7f9;

            & span {
                font-size: 15px;
            }

            & .errorButton {
                background-color: #eeb6c0;
                color: #d61346;
                width: 140px;
                font-weight: 800;
            }

            & .onlineButton {
                background-color: #23baa6;
                color: #777a7e;
                width: 140px;
                font-weight: 800;
            }

            & .deviceTitle {
                padding: 10px 5px 10px 20px;
                background-color: #f4f7f9;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }

    & .right-panel-details {
        margin-left: 30px;
        min-width: 700px;
    }
}

@media screen and (max-width: 1280px) {
    .device-details {
        margin-left: 0px;
    }
}


.deviceInventory {
    width: 100%;
    padding-top: 10px;
    display: block;

    & h2 {
        font-size: 30px;
        color: #2b6777;
    }

    & .title {
        display: flex;
        color: #777a7e;
    }

    & .titleBtn {
        display: flex;
        flex: auto;
        justify-content: left;
        padding: 5px;
        font-size: 15px;
    }

    & .titleFooter {
        & span {
            font-size: inherit;
        }
    }

    & .deviceList {
        display: flex;

        & .tree {
            width: 30%;
        }

        & .table {
            width: 70%;
        }
    }

    & .toolButton {
        padding: 2px;
    }

    & .label {
        padding-bottom: 20px;
    }

    & .rightButton {
        margin-left: auto;
        margin-bottom: 5px;
    }

    & .statisticBasic {
        text-align: center;
        min-height: 120px;
        cursor: pointer;
    }

    & .basicIcon {
        & :global(.icon__iconWrapper) {
            color: #2B6777;
        }
    }

    & .offlineIcon {
        & :global(.icon__iconWrapper) {
            color: #ec6c4c;
        }
    }

    & .onlineIcon {
        & :global(.icon__iconWrapper) {
            color: #52AB98;
        }
    }

    & .errorReportIcon {
        & :global(.icon__iconWrapper) {
            color: #d61346;
        }
    }

    & .downloadIcon {
        margin-top: -6px;
        margin-bottom: -12px;
    }
}

.log-content {
    margin: 15px;
    overflow-y: auto;
    max-height: 500px;
}

.baseDetails {
    background-color: white;
}

.base-details-rows {
    & :global(.ant-statistic-title) {
        font-size: 15px;
    }

    & :global(.row__rowLayout) {
        margin-bottom: 0px;
    }

    & :global(.ant-col) {
        border: solid 1px rgb(217 217 217 / 26%);

        & .detail-statistic {
            & span {
                font-size: 20px;
                overflow-wrap: break-word;
                text-overflow: ellipsis;
                display: block;
            }
        }
    }
}

.tamperDetails {
    color: #d61346;
    font-weight: 700;
}

.suffixDetails {
    color: #d61346;
    font-weight: 700;
    font-size: 12px;
}

.details-title {
    font-size: 25px;
    font-weight: bold;

    & .left {
        float: left;
    }

    & .right {
        float: right;
        text-overflow: ellipsis;
    }
}

.var-select-modal {
    top: 40%;
}

.emptyComponent {
    top: 40%;
    position: relative;
}

.backBtn {
    float: right;
    margin-right: 24px;
}

.editBtn {
    display: contents;
}

.actions-menu {
    color: #2b6777;
    font-size: 20px;
    padding: 5px 0px !important;
    border-bottom: none;
}

.edit-detail-statistic {
    & :global(.ant-form-item) {
        margin-bottom: 0px;
    }

    & .edit-statistic-title {
        margin-right: 5px;
    }

    & :global(.ant-input) {
        font-size: 20px;
        padding: 0px;
    }
}

.sub-card {
    margin-top: 5px;

    & strong {
        color: #52AB98
    }

    & span {
        display: block;
    }

    & :global(.ant-form-item) {
        margin-bottom: 0px;
    }

    & .sub-card-title {
        margin-right: 5px;
        display: inline;
    }

    & :global(.ant-form-item-control-input) {
        min-height: 0px;

        & :global(.ant-input) {
            font-size: 14px;
            padding: 0px;
            width: 100%;
        }
    }
}

.deploymentData {
    & :global(.ant-table-cell) {
        white-space: pre-wrap;
        vertical-align: top;
    }
}

.metrics-statistic-container {
    background: #ececec;

    & :global(.ant-card-body) {
        padding: 0px;
    }

    & :global(.row__rowLayout) {
        margin-bottom: 0px;
    }

    & .time-statistic {
        background-color: white;
        height: 100%;
        word-break: break-word;
    }
}

.attributeComponent {
    margin-top: 10px;
    float: right;
}

.deviceInventory-statistic-container {
    & :global(.ant-statistic-content) {
        display: grid;
        justify-content: center;

        & :global(.ant-statistic-content-value) {
            display: flex;
            justify-content: center;
            font-weight: bold;
        }
    }

    & :global(.ant-card) {
        border: 2px solid #e8eff2;
        border-radius: 5px;
        background-color: #f4f7f9;

        & :global(.ant-card-head) {
            border-bottom: none;
        }

        & :global(.ant-card-body) {
            padding: 0px;
        }
    }
}

.clientCollapse {
    border: 2px solid #e8eff2;
    border-radius: 5px;

    & :global(.ant-collapse-item) {
        padding-bottom: 2px;

        & :global(.ant-collapse-header-text) {
            display: flex;
        }
    }

    & :global(.ant-collapse-header) {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #c8d8e4;
    }

    & :global(.ant-collapse-content-box) {
        padding: 0px !important;
    }
}

.deviceGroupCollapse {
    & :global(.ant-collapse-header) {
        background-color: #f4f7f9;
    }

    & :global(.ant-card-head) {
        display: none;
    }

    & :global(.ant-card-body) {
        padding: 0px;
    }
}

.form-item-error {
    margin: 0px;
}

.organisation {
    display: flex;
    padding-bottom: 24px;

    & .select {
        padding: 2px;
    }
}

.clientCollapseTitleHeader {
    font-weight: bold;
    padding-right: 5px;
}

.deviceGroupCollapseTitleHeader {
    font-weight: bold;
    padding: 0px 5px;
}

.snapshots {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & .snapshotNameButton {
        padding: 0px;
    }

    & .separator {
        margin-top: auto;
        margin-bottom: 5px;
    }
}
