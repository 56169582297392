.tableWrapper {

    width: 100%;

    & .noDataWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.basicForm {
    & :global(.ant-table-cell) {
        & :global(.ant-checkbox-disabled .ant-checkbox-inner) {
            background-color: #e1f0eb;
        }
    }
}