.loginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px !important;
    width: 450px !important;
    margin: auto;


    & .header {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        white-space: nowrap;

        & .title {
            margin-bottom: 0 !important;
        }

        & .description {
            text-align: center;
            color: gray;
        }
    }
}
