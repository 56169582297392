.panel {
    display: flex;
}
.horizontal-collapse {
    transition: width 0.3s ease;
}
.collapse {
}
.horizontal-collapse-collapsed {
    width: 50px;
    transition: width 0.3s ease;
}
.right-panel {
    margin-left: 15px;
    width: 100%;
}