.levelMatrixTable {
    & :global(.ant-checkbox-checked) {
        & :global(.ant-checkbox-inner) {
            background-color: #52ab98;
            border-color: #52ab98;
        }
    }
}

.permissionGroupsCollapse {
    border: 2px solid #e8eff2;
    border-radius: 5px;

    & :global(.ant-collapse-item) {
        padding-bottom: 2px;
    }

    & :global(.ant-collapse-header) {
        flex-direction: row;
        align-items: center;
        background-color: #c8d8e4;
    }

    & :global(.ant-checkbox-checked) {
        & :global(.ant-checkbox-inner) {
            background-color: #52ab98;
            border-color: #52ab98;
        }
    }
}

.buttonsContainer {
    display: flex;
    padding-top: 5px;
}
