.row {
    display: flex;
    padding: 24px;
    justify-content: flex-end;
}

& .vertical-list {
    border: none;
}
& :global(.vertical-list__verticalListItemContent) {
    margin: 0px !important;
}