.spinner {
    width: 100%;
    display: block;
    & .form { }
}

.errors-alert { }

.submit-button {
    float: right;
}