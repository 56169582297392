.horizontalMenu {
    background: transparent;
    border-bottom: 0px;
    width: 100%;

    & .menuItem {
        color: #C8D8E4;
        display: inline;
        flex-display: row;
        justify-content: center;
        align-items: center;
    }
}

.subMenuItem {
    color: #627B87;
    display: inline;
}
.icon-wrapper {
    display: contents;
}
.icon-item {
    color: #C8D8E4;
    font-size: 20px;
}