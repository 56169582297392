.list {
    width: 100%;
}

.alert {
    margin-bottom: 10px;
}

.save {
    width: 100%;

    & .spinner {
        width: 100%;
        display: block;

        & .save_form {
        }

        & .control {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.client_form {
    width: 100%;

    & .control {
        display: inline-flex;
        align-items: center;
    }
}

.spinning {
    display: block !important;
}
