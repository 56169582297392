.listingPage  {
    width: 100%;
    & .title {
    }
    & .content {
        & .toolbar {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        & .table {
        }
    }
}
