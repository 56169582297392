.spinner {
    display: block;


    & .uploadKeys_form {
        & .control {
            display: inline-flex;
        }

        & .description {
        }
    }
    & .distribution_form {

        & .control {
            display: inline-flex;
            float: right;
        }
    }
}

.key_inventory {
    width: 100%;
    display: flex;
    flex-direction: column;
}
