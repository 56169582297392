.spinner {
    display: block;
}

.reports {
    width: 100%;

    & .deployment {
        width: 100%;
    }
    
    & .spinning {
        display: block !important;
    }

    & .alert {
        margin-bottom: 10px;
    }

    & .firmware_version {
        width: 100%;
    }

    & .snapshotsTable {
        & :global(.ant-table) {
            margin: 0px !important;
        }

        & table thead {
            display: none;
        }
    }

    & .packagesTable table thead {
        display: contents;
    }

    & .snapshot-comparison {
        width: 100%;

        & .label-right-align {
            margin-right: 5px;
            float: right;
        }
        & .default-row {
        }
        & .danger-row {
            color: red;
        }
        & .warning-row {
            color: orange;
        }
    }
}
.devices-table {
    width: 1220px;
}