.advanceTable {
    width: 100%;

    & .noDataWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    & .toolbar {
        display: inline-flex;
        align-items: center;
    }
    & .extraSpacing {
        margin-right: 5px;
      }
}
