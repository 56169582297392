.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    width: 100%;

    & h4 {
        margin-bottom: 0 !important;
    }

    & .basicForm {
        width: 100%;
    }
}
