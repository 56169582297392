.form {
    & .item {
        width: 100%;
    }
}

.uploadAlert {
    margin-bottom: 5px;
}

.control {
    display: flex;
}

.devicesTable {
    height: 100%;
}
