.list {
    width: 100%;

    & .depoymentGroupCollapse {
        & .actions-menu svg {
            margin: 0 !important;
        }
    }
}

.addTargets {
    display: flex;
    justify-content: flex-end;
}

.tabChild {
    display: block !important;
    width: 100%;

    & .functions {
        display: inline-flex;
        align-items: center;
    }
}

.spinner {
    display: unset;
}
.add {
    width: 100%;
    & .addDeploymentTargetsTable {
        & :global(.form-item__formItem) {
            margin-bottom: 0px;
            width: 100%;
        }
    }

    & .compareSettings {
        width: 100%;
        background-color: #FAFAFA;

        & .title {
            padding: 10px 30px;
            margin-bottom: 0px;
        }

        & .compareContent {
            height: 400px;
            overflow-y: auto;
            
            & .row {
                margin-bottom: 1px;
    
                & .number {
                    text-align: center;
                }
    
                & .item {
                    display: flex;
                    padding-left: 10px;
                }
                
                & .none {
                    background: repeating-linear-gradient(57deg,#0000,#0000 2px,#EBECF0 3px 3px,#0000 4px);
                }
        
                & .noChange {
                    padding-left: 24px;
                    background-color: #FEFEFE;
                }
                
                & .add {
                    background-color: #D4F7F3;
                }
            
                & .change {
                    background-color: #FFE214;
                }
            
                & .remove {
                    background-color: #FFEBE6;
                }
        
                & .icon {
                    padding-right: 10px;
                }
            }
        }

        & .footer {
            display: flex;

            & .text {
                margin: 2px 10px;
            }

            & .addIcon {
                background-color: #D4F7F3;
            }
        
            & .removeIcon {
                background-color: #FFEBE6;
            }
        
            & .changeIcon {
                background-color: #FFE214;
            }
        }
    }
}

.alert {
    margin-bottom: 10px;
}

.emptyComponent {
    & :global(.ant-empty-normal) {
        margin: 0px;
    }
}

.targetSettings {
    display: flex;

    & .settings {
        flex: 0 0 50%;
    }

    & .description {
        margin-left: 1rem;

        & > ul {
            margin: 0;
            padding-left: 1rem;

            & > li {
                margin-top: 0.5rem;
            }
        }
    }
}

.deploymentDetailCollapse > td {
    padding: 0px !important;

    & :global(.ant-collapse-header) {
        background-color: #f4f7f9;
        align-items: center !important;
    }

    & :global(.ant-collapse-content-box) {
        padding: 0px !important;
    }

    & :global(.ant-card-body) {
        padding: 5px 24px;
    }

    & .collapseHeader {
        display: flex;
        font-weight: bold;

        & .text {
            padding: 0px 5px;

            & .subText {
                padding: 0px 5px;
                color: #52ab98;
            }
        }
    }

    & .deviceGroupCollapseContain {
        & :global(.ant-collapse-header) {
            background-color: #f5f5f5;
        }

        & :global(.vertical-stepper__stepItemTitle) {
            font-size: 14px;
        }
    
        & .stepsItem {
            & .stepHeadder {
                color: #52AB98;
            }
    
            & :global(.ant-steps-item-icon) {
                background-color: #52AB98;
            }
    
            & :global(.ant-steps-icon) {
                color: #f5f7f9;
            }
        }
    }
}

.progress-status-div {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.progress-status-icon-div {
    margin-top: 4px;
    margin-bottom: 5px;
}
.progress-error-status {
    color: #d61346;
}
.progress-success-status {
    color: #52c41a;
}

.reload-device-group-btn {
    background-color: #e5f0f5 !important;
    border-color: #c8d8e4 !important;
}
