.transfer-form {
    & :global(.file-manual-upload__uploadBox) {
        margin: 0px;
    }
}

.spinner {
    display: block;
}

.button {
    margin: 10px;
    display: inline-flex;
    float: right;
}