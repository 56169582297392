.copyToClipboardButton {
    width: 20px;
    height: 20px;
    border-radius: 6px;
}

.copyToClipboardButton:active {
    color: #777A7E;
    border-color: #d9d9d9;
    background: #F5F5F5;
}

.copyToClipboardButton svg {
    width: 12px;
    height: 12px;
}
