.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & .content {
    padding: 5px;
  }
  & .footer {
    padding-top: 5px;
    margin-bottom: 0;
    margin-top: 5px;
    display: inline-flex;
    justify-content: space-between;
    & span {
      font-size: 12px;
    }
  }
}
