.baseErrorWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > .baseError {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: auto;
        width: 600px;

        & div:first-child {
            margin-right: 20px;
        }

        & > .baseErrorContent {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }

        & > .baseErrorFooter {

        }
    }
}
