.horizontalStepper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & .stepContent {
        flex-grow: 2;
        margin-top: 30px;
    }

    & .actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}