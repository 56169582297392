.header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & .brand {
        height: 100%;
        display: flex;
        align-items: center;
        width: max-content;
    }

    & .brand .title {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 700;
    }

    & .mainMenu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        width: 500px;
    }

    & .profile {
        display: block;
    }
	& .icon-hover {
        cursor: pointer;
    }
}

.profileDropdown {
    width: fit-content;
}
