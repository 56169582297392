.deviceGroupLabel {
    margin-bottom: 10px;
}
.transfer {
    min-height: 400px;
    max-height: 800px;

    & :global(.ant-transfer-list) {
        max-width: calc(50% - 52px);
    }
}
