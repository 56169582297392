.first_login {
    width: 600px;
    height: auto !important;
    display: flex;
    margin: auto;

    & .description {
        padding-bottom: 20px;
    }
}
