.list {
    width: 100%;
}

.save {
    width: 100%;

    & .spinner {
        width: 100%;
        display: block;

        & .save_form {
        }

        & .control {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.extraSpacing {
    margin-right: 5px;
    min-width: 400px;
}
