.alert {
    margin-bottom: 10px;
}

.save_form {
    width: 100%;
    display: block;
    & .control {
        display: flex;
        justify-content: flex-end;
    }
}

.group_switch {
    background-color: #52AB98;
}

.hidden {
    display: none;
}

.orgSelect {
    min-width: 400px;
}