.spinner {
    display: block;
    width: 100%;
}

.formItem {
    & :global(.ant-form-item) {
        display: block !important;
    }
}

.row {
    display: flex;
    padding: 24px;
    justify-content: flex-end;
}

.transfer {
    & :global(.ant-transfer-list) {
        width: 100%;
    }
}

.title {
    padding-bottom: 24px !important;
}

.createdView {
    margin: 16px 24px;
    max-width: 840px;

    & .backButton {
        margin-top: 24px;
        margin-left: 0;
    }
}

.no-margin-form-item {
    margin: 0px
}

.row-layout-center {
    margin-bottom: 0px;
    margin-left: 10%;
}

.description-center {
    margin-bottom: 0px;
    margin-left: 20px;
    display: block;
}