.verticalStepper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & .stepItemTitle {
        font-size: 16px;
    }

    & .stepItemDescription {
        color: black;
        white-space: pre-wrap;
        font-size: 13px;
    }
}