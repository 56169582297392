.notification-hub {
    & ul {
        padding: 0px;
    }
    & li {
        margin: 10px 5px;
    }
}
.notification-item {
    background-color: #F4F7F9;
    & .left {
        float: left;
    }
    & .right {
        float: right;
        & .icon-wrapper {
            font-size: 8px;
            display: inline;
        }
    }
    & .row-layout-center {
        align-items: center;
        margin-bottom: 0px;
    }
    & .delete-notification-span {
        cursor: pointer;
        & .delete-notification-icon {
            font-size: 14px;
        }
    }
}
.notification-footer{
    margin-top: 10px;
    margin-bottom: 0px;
    & span {
        color: #627B87;
    }
}

.save-footer {
    display: flex;
    float: right;
}
