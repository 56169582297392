.verticalMenu {
    width: 100%;
    background-color: #E8EEF2;

    & .menuItem {
        background-color: #E8EEF2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    } & .menuItem:hover {
        background-color: #F5FAFA !important;
        color:#52AB98;
    }
}
