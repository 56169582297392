.formItem {
    & :global(.ant-form-item) {
        display: block !important;
    }
}

.linkButton {
    & :global(.ant-typography) {
        color: #096dd9;
    }
}

.actionButtons {
    display: inline-flex;
}

.preview {
    & .vertical-list {
        border: none;
    }
    & :global(.vertical-list__verticalListItemContent) {
        margin: 0px !important;
    }
    & :global(.ant-list-item) {
        padding: 5px 0;
    }
    & :global(.ant-card-head-title) {
        font-size: 16px;
    }
    & .contents {
        height: 500px;
        overflow-y: auto;
    }
    & .added {
        color: #23baa6;
        font-weight: bold;
    }
    & .removed {
        color: #d61346;
        font-weight: bold;
    }
    & .changed {
        color: #ec6c4c;
        font-weight: bold;
    }
}
