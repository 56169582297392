.spinning {
    display: block !important;
}

.label{
    padding-bottom: 15px;
}

.tableActions {
    display: flex;

    & :global(.ant-menu-title-content) {
        color: #52ab98;
    }

    & .deleteButton {
        display: flex;
        align-items: center;
    }
}

.buttons {
    display: flex;
    padding-top: 24px;
    justify-content: flex-end;
}

.attribute {
    display: flex !important;
    margin-bottom: 8px;
}

.csv-import-box {
    margin: 0px;
    display: block;
}

.orgSelect {
    min-width: 400px;
}
