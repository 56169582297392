.infoDialog {
    width: 600px !important;
}

.configurationSign {
    display: block !important;

    & .formUploadFiles {
        width: 1000px;

        & .simpleFileUpload {
            margin-top: 0px;
            margin-left: 0px;

            & :global(.file-manual-upload__spacer) {
                margin-top: 0px;
            }
        }

        & .control {
            margin-left: auto;
            margin-right: 5px
        }
    }
    
    & .formSigning {

        & .uploadAlert {
            margin-bottom: 5px;
        }
        
        & .version {
            margin-left: 15px; 

            & .files {
                margin-top: 30px; 
                margin-bottom: 30px;
                color: #2B6777;

                & .item {
                    display: flex;

                    & .icon {
                        margin-right: 10px; 
                    }
                }
            }

            & .control {
                display: flex;

                & .signFiles {
                    margin-left: auto;
                }
            }
        }
    }

    & .formFinished {
        & .message {
            padding-bottom: 10px;
        }
    }
}
