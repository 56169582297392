.uploadBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 15px;
}
.dragger {
    & :global(.icon__iconWrapper) {
        justify-content: center;
        color: #2B6777;
    }
}
.spacer {
    margin-top: 15px;
    margin-bottom: 5px;
    & :global(.ant-upload) {
        background-color: unset;
    }
}

.uploadButton {
    width: 100px;
}
