.loginForm {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & .title {
        
    }

    & .inputField {
        width: 100%;
        
        & > input {
            text-align: center;
        }
    }

    & .button {
        width: 100%;
    }

    & .forgotPasswordRow {
        display: flex;
        margin-bottom: 20px;
        align-items: center;

        & .rememberMe {
            margin-right: auto;
            margin-left: 0;
        }

        & .forgotPassword {
            margin-right: 0;
            margin-left: auto;
        }
    }
}
