.shell_prompt {

    & .spinner {
        width: 100%;
        display: block;

        & .shell_form {
        }
    }
    & .control {
        display: inline-flex;
        float: right;
    }
}