.searchDevices {
    display: flex;

    & .label {
        padding: 5px 10px;
        font-weight: 500;
        font-size: larger;
    }
}

.spinning-component {
    display: block;
}

.deviceUuidHyperlinkBlock {
    display: flex;
    align-items: center;
}

.package-component {
    & .title { 
        font-size: x-large;
    }
    & .description {
        margin-top: 20px;
        font-weight: normal;
        font-size: 15px;
    }
}
